.custom-dropdown-container {
    position: relative;
    display: inline-block;
    width: 100%;
}
.custom-dropdown-container button {
    width: 100%;
    text-align: left;
    height: 40px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #ccc;
}
.custom-dropdown-options {
    position: absolute;
    background-color: #fff;
    min-width: 200px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 10px;
}

.custom-dropdpowm-options-container {
    max-height: 500px;
    overflow-y: auto;
}

.custom-dropdown-items {
    padding: 10px;
    display: flex;
}
.custom-dropdown-items-selected {
    background-color: #559fff;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    color: #fff;
}
.custom-dropdown-items-selected:hover, .custom-dropdown-items:hover {
    background-color: rgb(199, 223, 255);
    border-radius: 5px;
    cursor: pointer;
}

.custom-dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.custom-dropdown-search {
    border: none;
    width: 100%;
    cursor: pointer;
}

.custom-dropdown-search:focus-visible {
    outline: none;
}

.custom-dropdown-search-container {
    width: 100%;
}
.custom-dropdown-toggleIcon {
    display: flex;
    align-items: center;
}
.custom-dropdown-toggleIcon span {
    margin-right: 5px;
    color: #ccc;
}