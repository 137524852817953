.card {
  justify-content: center;
  align-items: center;
}

.project-text, .long-project-text {
  font-size: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.home-page-container {
  margin-bottom: 80px;
}

.home-card-link {
  height: 152px;
}
.total-cost-button{
  display: flex;
  justify-content: flex-end;
  width: 80vw;
  max-width: 1000px;
  margin: auto;
  padding: 0 1rem;
}
.project-card-body {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.project-card-body.card-body {
  padding: 14px;
}
.project-card-title {
  flex-direction: column;
  width: 100%;
}
.project-logo-container {
  transition: opacity 0.3s ease;
}
.project-card-container:hover {
  border: solid transparent;
  border-width: 0 0 5px;
  border-radius: 8px;
  transform: scale(1.05);
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #6d30ef, #2d7ef2);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: transform 0.3s ease, border 0.1s ease;
}
.project-card-container:hover .project-logo-container {
  opacity: 0;
  height: 0;
}

.project-card-container:hover .project-text {
  max-width: 100%;
  white-space: normal;
  font-size: 28px;
}
.project-card-container:hover .long-project-text{
  max-width: 100%;
  white-space: normal;
  font-size: 22px;
}