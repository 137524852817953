.multi-dropdown {
    color: black;
    .btn.show {
        background-color: rgba(90, 93, 157, .1);
        color: black;
    }
    .btn:disabled {
        background-color: rgba(90, 93, 157, .1);
        color: black;
    }
}
.multi-dropdown :hover {
    color: black;
}

.multi-dropdown-selector {
    margin-bottom: 2rem;
}

.dropdown-badge-container {
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    row-gap: 4px;
}
.input-badge-container {
    margin-bottom: 0rem;
}
.faker-libraby-switch {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
}

.wrapper-container {
    margin-bottom: 1.5rem;
}

.accordion-body-container {
    overflow:auto
}
.accordion-body {
    overflow-y: scroll !important;
}
