.error-page-container {
  font-size: 1.5rem;
  text-align: center;
}

.error-text-container {
  height: calc(90vh - 100px);
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.home-button {
  margin: 0 10px;
}

.error-page-container header {
  flex-flow: column;
}