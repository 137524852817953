.feedback-container {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 2rem;
}

.feedback-text {
  margin: 0;
}

.btn-container {
  margin-left: 20px;
}

.feedback-message {
  width: 500px;
}
