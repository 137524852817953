.icon-dropdown-wrapper {
  display: flex;
  align-items: center;
}
.icon-edit-button {
  margin-left: 24px;
  border: none;
  cursor: pointer;
  padding: 0px;
  color: #2781f2;
  font-size: 23px;
  background-color: white;
}
.icon-edit-button:hover {
  border: none;
  color: #1364c6;
  background-color: white;
}
.icon-edit-button:disabled {
  background-color: white;
  color: rgb(116, 115, 115);
}

.icon-delete {
  margin-left: 13px;
  color: rgb(203, 2, 2);
  font-size: 20px;
}
.icon-delete:hover {
  color: rgb(165, 1, 1);
  font-size: 21px;
}

.knowledge-delete-button:disabled {
  background-color: white;
  filter: grayscale(100%);
}
.instance-error {
  display: flex;
  flex-direction: column;
  background-color: #f8d7d8;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  color: #58151c;
  font-size: 13px
}
.knowledge-document-status {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.refresh-loader {
  border: none;
  background: transparent;
  display: flex;
  font-size: 12px;
}
.knowledge-status-label {
  font-size: 14px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 5px;
  width: auto;
}
.knowledge-status-label-complete {
  color: white;
  background-color: rgb(25 135 84);
}

.knowledge-status-label-pending {
  background-color: #fbf07c;  
}

.hide-knowledge-document-status {
  display: none;
}
.knowledge-status-label-failed {
  background-color: #dc3545;
  color: white;
}