.user-input,
.file-input,
.output-container {
  margin: 0 auto 30px;
}
.modal-header {
  font-size: 1.5rem;
  font-weight: bold;
}
.modal-footer {
  display: flex;
  justify-content: center;
}

.output-container {
  margin-top: 32px;
  display: 'flex';
  align-items: 'center';
  border: 1px solid #888888;
  padding: 16px;
  border-radius: '8px';
  width: 50vw;
}

.create-new-instance-link {
  margin-top: 8px;
}
.h4 {
  font-size: 1.25rem;
}
