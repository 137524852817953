.id-column {
  min-width: 50px;
}
.name-column {
  min-width: 150px;
}
.documents-column {
  min-width: 200px;
}

.knowledge-delete-button {
  border: none;
  cursor: pointer;
  padding: 0px;
  background: none;
  margin-left: 20px;
}

.knowledge-delete-button:hover {
  border: none;
  background: none;
}
.knowledge-icon-delete {
  color: rgb(203, 2, 2);
  font-size: 18px;
}
.knowledge-icon-delete:hover {
  color: rgb(165, 1, 1);
  font-size: 19px;
}