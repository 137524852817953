.application-card {
  height: 180px;
  border: 1px solid #3463aa;
  align-items: flex-start;
}

.activity-button {
  display: flex;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background-color: rgb(52, 121, 249);
  color: white;
}

.activity-button:hover {
  background-color: rgb(21, 100, 247);
}

.application-card:hover {
  border: solid transparent;
  border-width: 0 0 5px;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #6d30ef, #2d7ef2);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transform: scale(1.05);
  transition: 0.05s;
}

.applications-back {
  width: 80vw;
  max-width: 1000px;
  margin: auto;
  padding: 0rem 1rem;
}

.application-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.application-card-content img {
  height: 2.5rem;
  width: 2.5rem;
}
.application-card-content .title-text {
  margin: 5px 0 5px;
  padding-left: 4px;
}

.application-card .card-body {
  padding: 14px;
  align-items: flex-start;
}

.description-text {
  font-size: 0.8rem;
  padding-left: 4px;
}

.group-title {
  font-size: 1.8rem;
  font-weight: 600;
  padding-left: 1rem;
  margin-top: 2rem;
}
