.toast-container {
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1050;
}

.toast-header {
    color: white;
    opacity: 1;
    border: none;
    .btn-close {
        font-size: 0.7rem;
        margin-left: auto;
    }

}

.close-icon {
    justify-content: flex-end;
    padding: 0.5rem 1rem 0rem 0rem;
}

.toast-body {
    padding-top: 0rem;
}

.modal-toast {
    z-index: 1060;
}

.regular-toast {
    z-index: 1050;
}

.custom-toast-width {
    width: 30rem;
}