.instance-name,
.topic-name,
.file-input,
.knowledge-name {
  margin: 0 auto 32px;
}

.knowledge-id-list {
  /* border: 1px solid #ccc; */
  min-height: 200px;
  height: auto;
  overflow-y: scroll;
  padding: 0 16px;
}

.basic-details-knowledge-wrapper {
  width: 100%;
  
}
.basic-details-wrapper {
  width: calc(50% - 16px);
  
}

.button-container {
  margin-top: 16px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}
.two-button-container {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.modal-button.btn-primary {
  width: 150px;
  height: 40px;
}

.modal-button.btn-secondary {
  width: 112px;
  height: 40px;
}
.button-container-secondary {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  gap: 12px;
}

.button-container-tertiary {
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  gap: 12px;
  float: right;
}

.modal-dialog {
  min-width: 50vw;
  margin-top: 15vh;
}
.modal-header {
  padding: 24px;
}

.modal-body {
  padding: 24px;
}

.modal-helper-text {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(13, 110, 253);
}

.template-name-input {
  margin-bottom: 16px;
}

.modal-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.modal-header-title {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
}

.modal-step-number {
  color: #707070;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.basic-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 32px;
  flex-wrap: wrap;
}
.basic-details-container-checkbox {
  display: flex;
  margin: 5px 0;
}

.label-text {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}

.knowledge-list-text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.template-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.template-field-wrapper {
  width: 100%;
}

.knowledgeId-list-wraper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.knowledgeId-list-item {
  padding-right: 16px;
  padding-left: 16px;
  border-right: 1px solid #878787;
}

.knowledgeId-list-item:last-child {
  padding-right: 0;
  border: none;
}

.keyValue-container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0.8rem 0;
}

.custom-slider-wrapper {
  position: relative;
  font-weight: bold;
}
.precise-label {
  left: 0;
}
.creative-label {
  position: absolute;
  right: 0;
}

.rta {
  /* font-size: 1rem !important; */
  line-height: 1.5rem;
}
.rta__textarea {
  line-height: 1.5rem;
}

.basic-details-container-step2 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
}
.basic-details-container {
  .form-text {
    color: #000;
  }
  .file-input {
    margin-bottom: 10px;
  }
}

.markdown-option-labels {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.cloudprovider-options{
  display: flex;
  justify-content: space-between;
}
.token-display{
  margin-top: 10px;
  font-size: 0.875rem;
  color: #333333;
}
.tokens-div{
  margin-top: 10px;
  font-size: 0.75rem;
}
.progress-bar{
  width: 250px;
}
.token-limit-exceeded-warning-msg{
  color: red;
  font-size: 0.75rem;
}
.token-counter-bar-wrapper{
  width: 100%;
}
.token-count-progress{
  height:10px;
}
.cloudprovider-model-selector{
  display: flex;
  justify-content: space-evenly;
  width: 100%;

}
.cloudprovider-model-selector-width{
  width:100%
}
.model-selector{
  width: 100%;
}
.gap{
  margin-left: 32px;
}
.choose-file-note{
  font-size: 0.875rem;
  color: #808080;
  margin-top: 5px;
}
.upload-error{
  color: red;
  font-size: 0.875rem;
  margin-bottom: 15px;
}
.llm-guard-upcoming-feature-text{
  font-size: .875rem;
  margin-bottom: 5px;
  margin-left: 10px;
  font-weight: 400;
}

.label-container.form-label {
  display: flex;
}
.add-remove-output-columns-button-column {
  padding-left: 0;
  display: flex;
  align-items: flex-start;
}

button.add-remove-output-columns {
  margin-top: 6px;
  padding: 12px;
  width: 12px;
  height: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
button:not(:disabled).sorting-button{
cursor:grab;
}
.sorting-button {
  margin-bottom: 10px;
  background-color: white;
  border: none;
}

.sorting-button:hover {
  background-color: white;
}
.output-column-field{
  margin-left:0px;
}
.output-column-container{
  overflow-y: auto;
  padding-top:20px ;
  padding-bottom: 20px;
  /* border: 1px solid black; */
}
.margin-left{
  margin-left: 70px;
}
.output-column-parent-container{
  padding-top: 20px;
  /* border: 1px solid black; */
  margin: auto auto;
  padding-bottom: 20px;
  scroll-behavior: smooth;
}
.fixed-output-columns{
  margin:0 0px;
  /* width:80%; */
}
.new-name-check{
  margin-top: 15px;
  width:200%;
}
.model-recommendation-msg {
  font-size: 0.875rem;
  color: #808080;
  margin-top: 5px;
}