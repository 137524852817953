.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.table th,
.table td {
  text-align: left;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
  white-space: nowrap;
}

.table-responsive {
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .table th,
  .table td {
    display: block;
  }
  .table td {
    text-align: right;
  }
  .table th::before {
    content: attr(data-header);
    font-weight: bold;
  }
}

.back-button {
  font-size: 1.25rem;
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-start;
  & > a {
    color: #000;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
}

.view-details {
  color: blue;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;
  white-space: nowrap;
}

.view-details.active {
  color: rgb(2, 137, 152);
  text-decoration: none;
  cursor: pointer;
}

.pagination {
  margin-top: 10px;
  justify-content: center;
}
.pagination button {
  margin: 0.2rem;
  display: inline-block;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.pagination-button {
  border: none;
  background-color: rgb(52, 121, 249);
  color: white;
}
.pagination-button:hover {
  background-color: rgb(21, 100, 247);
}

.pagination-pages {
  border: 1rem;
  border-color: #999999;
  background-color: #f5f3f3;
}
.pagination-pages:hover {
  background-color: #e6e5e5;
}
.pagination-pages.active {
  background-color: #e6e5e5;
  font-weight: bold;
}

.activity-back-button-container {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  font-size: 1.25rem;
  & > a {
    color: #000;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
}

.download-activity-button-tag {
  color: white;
}

.download-activity-button {
  display: flex;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #198754;
  color: white;
  text-decoration: none;
}
.download-btn-loading {
  display: flex;
  width: 100px;
  height: 38px;
  align-items: center;
  justify-content: center;
}
