.user-input,
.file-input,
.output-container {
  margin: 0 auto 30px;
}

.modal-header {
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.output-container {
  margin-top: 32px;
  display: 'flex';
  align-items: 'center';
  border: 1px solid #888888;
  padding: 16px;
  border-radius: '8px';
}

.create-new-instance-link {
  margin-top: 8px;
}



.mic-btn {
  width: 8rem;
}

.mic-btn.btn-danger {
  margin-left: 1rem;
}

.upload-spinner > .spinner-border {
  width: 15px;
  height: 15px;
}

.upload-spinner {
  margin: 5px 0px;
}

.upload-msg {
  margin: 5px 0;
}

.upload-msg > span {
  font-size: 14px;
  font-weight: 500;
}

.back-button-container {
  display: flex;
  justify-content: space-between;
}

.disable-text {
  color: #888888;
}

.option-labels {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.user-story-generator-submit-button {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
  margin-left: auto;
  margin-right: auto;
}

.download-button {
  background-color: green;
}
.btn.btn-primary.active,
.btn.btn-primary:active {
  color: #0a53be;
  background-color: #fff;
  border-color: #fff;
  outline: none;
}

.user-story-generator-submit-container {
  display: flex
;
  justify-content: center;
}