.chatbox-container {
  background-color: 'white';
  border: '1px solid #CCC';
  border-radius: '10px';
  position: relative;
}

.chat-wrapper {
  height: 400px;
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
  border: 1px solid #efefef;
  border-left: 0;
  border-right: 0;
  border-radius: 1px;
}

.individual-chatbox {
  background-color: #fcfcfc;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  &.user-chat {
    background-color: #efefef;
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.assistant-chat {
    position: relative;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    & > .download-button-container {
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }
}

.chat-user-input {
  display: flex;

  .user-prompt-input {
    padding: 0.5rem;
    line-height: 1;
    height: 2rem;
    margin-right: 1rem;
    resize: none;
    max-height: 300px;
  }

  .btn-primary,
  .clear-chat-btn {
    width: 3rem;
    padding: 8px;
    height: 2rem;
    line-height: 0.5;
    border-radius: 6px;
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
.image-rendering {
  display: flex;
  column-gap: 20px;
}
.img-style {
  height: auto;
  width: 150px;
  margin-top: 10px;
  margin-right: 20px;
  padding: 0px;
}
.img-name-style {
  margin-top: 5px;
}
.feedback {
  width: 100%;
  justify-content: flex-start;
  margin-top: 5px;
}

p img {
  /* height: 120px; */
  width: 300px;
}
.image-div{
  width: 150px;
}

.chatbox-markdown {
  table {
    border-collapse: collapse;
    width: 100%;
    background: #f5f4f4;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  th {
    text-align: left;
    background-color: #e6e5e5;
  }

  h1 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  h2 {
    font-size: 1.1rem;
  }
  h3 {
    font-size: 1rem;
  }
  h1,
  h2,
  h3 {
    margin-bottom: 0rem;
  }
  p,
  ul,
  ol,
  li {
    line-height: 1.2rem;
  }
  p {
    margin-bottom: -8px;
  }
  ul,
  ol {
    margin-top: -20px;
    margin-bottom: -16px;

    li {
      margin-top: -4px;
      margin-bottom: -4px;
    }

    p {
        margin-top: -19px;
    }
  }
}

.regenerate-icon {
  margin-left: 0.3rem;
}
.regenerate-btn-container {
  display: flex;
  justify-content: flex-end;
}
.regenerate-btn, .regenerate-btn:hover {
  border: none;
  background: none;
  color: #2781f2;
  padding: 0px;
}
.copy-text{
  margin-right:10px ;
}
.copy-message{
  margin-left:5px;
}
.copied_text{
    margin: 0 auto;
    left: 0;
    right: 0;
    background-color: black;
    color: white;
    width: 200px;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    position: absolute;
    bottom:50px;
}