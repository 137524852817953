.stepper-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.2rem;
}

.steps {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.step {
  display: flex;
  position: relative;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.step:first-child::before {
  content: none;
}

.step:last-child::after {
  content: none;
}

.step::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 15px;
  left: -50%;
  z-index: 1;
}

.step::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 1;
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  z-index: 2;
}

.step-title {
  font-weight: bold;
}

.step-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
}
