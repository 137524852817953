.advanced-settins-submit {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.advanced-settins-submit:disabled {
  background: rgb(156, 156, 156);
  border-color: rgb(141, 141, 141);
}

.enabled-btn {
  background: #2781f2;
}

.disabled-btn {
  background: rgb(156, 156, 156);
  border-color: rgb(141, 141, 141);
}
.disabled-btn:hover {
  background: rgb(121, 120, 120);
  border-color: rgb(121, 120, 120);
}
