.logout-page {
    min-height: 65vh;
    font-family: 'Inter', sans-serif;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  footer {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-container {
    position: relative;
  }
  .footer-container > .footer-waves-horizontal {
    width: 100%;
    margin: 0;
    height: 100%;
    margin-top: 32px;
  }

  .footer-container > .footer-waves-corner {
    width: 30%;
    margin: 0;
    height: 100%;
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 25px;
  }